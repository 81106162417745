<script setup lang="ts">
import type { Database } from "~/types/database.js";

const props = defineProps<{
  enableFilters?: boolean;
  initFilters: string[];
}>();

const database = useDatabase();

const filtersInner = ref<null | HTMLElement>(null);

const openFilters = ref<Record<string, true | undefined>>({});

const filterBy = ref<Record<string, true | undefined>>({});

const openRow = ref<null | number>(null);

const searchKey = ref<null | string>(null);

const sortBy = ref("");

const loadChunk = ref(20);

const loadTotal = ref(20);

const showFilters = ref(false);

const headerCells = computed((): string[] => {
  return [
    headers.value[0] ?? "",
    "Topic", // Hard-coded as text not in CSV
    "Species", // Hard-coded as text not in CSV
    headers.value[3] ?? "",
    headers.value[4] ?? "",
  ];
});

const headers = useHeaders();

const species = useSpecies();

const topics = useTopics();

const jurisdictions = useJurisdictions();

const actTypes = getActTypes(headerCells.value);

const statuses = getStatuses(headerCells.value);

const stickyScroll = () => {
  if (!filtersInner.value) {
    return;
  }

  filtersInner.value.style.maxHeight
    = String(window.innerHeight - filtersInner.value.getBoundingClientRect().y - 24) + "px";
};

const toggleFilterBy = (filter: string) => {
  if (filterBy.value[filter]) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete filterBy.value[filter];
  } else {
    filterBy.value[filter] = true;
  }
};

const toggleFilterOpen = (filter: string) => {
  if (openFilters.value[filter]) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete openFilters.value[filter];
  } else {
    openFilters.value = {}; // Comment this out to have multiple filters open at once

    openFilters.value[filter] = true;
  }
};

const resetFilters = () => {
  filterBy.value = {};
  openFilters.value = {};
};

const countActiveFilters = (filters: null | string[] = null) => {
  if (!filters) {
    return Object.keys(filterBy.value).length;
  }

  return filters.filter((filter) =>
    Object.keys(filterBy.value).includes(filter),
  ).length;
};

const filteredRows = computed((): Database["data"] => {
  let filtered = [...database.value?.data ?? []];

  if (searchKey.value) {
    // Search titles and descriptions
    filtered = filtered.filter((row) => {
      return (
        row[headers.value[0] ?? ""]
          ?.toLowerCase()
          .includes((searchKey.value ?? "").toLowerCase())
          ?? row.Description?.toLowerCase().includes(
            (searchKey.value ?? "").toLowerCase(),
          )
      );
    });
  }

  // This filtering first checks if any filters in a particular filter
  // category are applied. If so, it checks whether a row meets _any_ of the
  // criteria within that category. Conversely, a row must meet _all_ of the
  // criteria across multiple filter categories. For example, selecting
  // both Cows & Pigs will show rows that are either Cows or Pigs, but if you
  // select USA it will show rows that are +Cows +USA, or +Pigs +USA, but _not_
  // rows that are +USA -Cows -Pigs.

  if (species.value.filter((filter) => filterBy.value[filter]).length > 0) {
    filtered = filtered.filter(
      (row) =>
        species.value.filter(
          (filter) => filterBy.value[filter] && row[filter] === "Yes",
        ).length > 0,
    );
  }

  if (topics.value.filter((filter) => filterBy.value[filter]).length > 0) {
    filtered = filtered.filter(
      (row) =>
        topics.value.filter(
          (filter) => filterBy.value[filter] && row[filter] === "Yes",
        ).length > 0,
    );
  }

  if (jurisdictions.value.filter((filter) => filterBy.value[filter]).length > 0) {
    filtered = filtered.filter(
      (row) =>
        jurisdictions.value.filter(
          (filter) => filterBy.value[filter] && row[headers.value[1] ?? ""] === filter,
        ).length > 0,
    );
  }

  if (actTypes.filter((filter) => filterBy.value[filter]).length > 0) {
    filtered = filtered.filter(
      (row) =>
        actTypes.filter(
          (filter) => filterBy.value[filter] && row[headers.value[3] ?? ""] === filter,
        ).length > 0,
    );
  }

  if (statuses.filter((filter) => filterBy.value[filter]).length > 0) {
    filtered = filtered.filter(
      (row) =>
        statuses.filter(
          (filter) => filterBy.value[filter] && row[headers.value[4] ?? ""] === filter,
        ).length > 0,
    );
  }

  if (sortBy.value !== "") {
    filtered.sort((a, b) => {
      if (
        [
          headerCells.value[0],
          headerCells.value[3],
          headerCells.value[4],
        ].includes(sortBy.value)
      ) {
        return a[sortBy.value]?.localeCompare(b[sortBy.value] ?? "") ?? 0;
      } else if (headerCells.value[1] === sortBy.value) {
        return getTruthyTopics(b).length - getTruthyTopics(a).length;
      } else {
        return getTruthySpecies(b).length - getTruthySpecies(a).length;
      }
    });
  }

  return filtered;
});

watch(showFilters, () => {
  if (showFilters.value) {
    document.body.classList.add("z-index-reset");
  } else {
    document.body.classList.remove("z-index-reset");
  }
});

onMounted((): void => {
  window.addEventListener("scroll", stickyScroll);

  window.addEventListener("resize", stickyScroll);

  stickyScroll();

  if (props.initFilters.length) {
    props.initFilters.forEach((filter) => {
      toggleFilterBy(filter);
    });
  }
});

onUnmounted((): void => {
  window.removeEventListener("scroll", stickyScroll);

  window.removeEventListener("resize", stickyScroll);
});

const pending = ref(false);

const success = ref(false);

const download = async (event: Event) => {
  pending.value = true;

  const formData = new FormData(event.target as HTMLFormElement);

  try {
    const data = await $fetch("/api/pardot/submit", {
      method: "POST",
      body: Object.fromEntries(formData.entries()),
    });

    if (data === "ok") {
      success.value = true;

      if (database.value?.download) {
        window.location.href = database.value.download;
      }
    } else {
      throw new Error(data);
    }
  } catch (error) {
    alert("Something went wrong, please try again later");

    pending.value = false;

    // eslint-disable-next-line no-console
    console.error(error);
  }

  turnstile.value?.reset();

  pending.value = false;
};

const turnstile = ref();

const turnstileToken = ref("");
</script>

<!-- eslint-disable-next-line vue/max-lines-per-block -->
<template>
  <div class="data-table">
    <MarginContainer :width="1920">
      <AppButton
        class="tablet-down secondary"
        @click="showFilters = true"
      >
        <div>
          Filter by
          <span v-if="countActiveFilters()">({{ countActiveFilters() }})</span>
        </div>
        <Icon
          name="icons:filter"
          :size="32"
        />
      </AppButton>
      <div
        v-if="enableFilters"
        ref="filters"
        class="filters"
        :class="{ open: showFilters }"
      >
        <AppNavButton
          :open="true"
          @click="showFilters = false"
        />
        <div ref="filtersInner">
          <div class="search">
            <Icon
              name="icons:search"
              :size="20"
            />
            <input
              v-model="searchKey"
              type="search"
              placeholder="Search the database"
              @keyup.enter="showFilters = false"
            />
          </div>
          <div class="sort-by">
            Sort by:
            <select v-model="sortBy">
              <option
                selected
                value=""
              >
                Default
              </option>
              <option
                v-for="(headerCell, index) in headerCells"
                :key="index"
                :value="headerCell"
              >
                {{ headerCell }}
              </option>
            </select>
          </div>
          <div class="filter-header">
            <span>Filter by:</span>
            <span v-if="!countActiveFilters()">All ✓</span>
            <span
              v-else
              class="clear"
              @click="resetFilters"
            >Clear</span>
          </div>
          <div class="filter-group">
            <div
              class="filter-group-title"
              @click="toggleFilterOpen(headerCells[1] ?? '')"
            >
              <div>
                {{ headerCells[1] }}
                <span v-if="countActiveFilters(topics)">
                  ({{ countActiveFilters(topics) }})</span>
              </div>
              <AppDropdownButton :open="openFilters[headerCells[1] ?? '']" />
            </div>
            <div v-if="openFilters[headerCells[1] ?? '']">
              <label
                v-for="(topic, index) in topics"
                :key="`topicFilter${index}`"
                @click.prevent="toggleFilterBy(topic)"
              >
                <input
                  type="checkbox"
                  :checked="filterBy[topic]"
                />
                <span>{{ topic }}</span>
              </label>
            </div>
          </div>
          <div class="filter-group">
            <div
              class="filter-group-title"
              @click="toggleFilterOpen(headers[1] ?? '')"
            >
              <div>
                {{ headers[1] }}
                <span v-if="countActiveFilters(jurisdictions)">
                  ({{ countActiveFilters(jurisdictions) }})</span>
              </div>
              <AppDropdownButton :open="openFilters[headers[1] ?? '']" />
            </div>
            <div v-if="openFilters[headers[1] ?? '']">
              <label
                v-for="(jurisdiction, index) in jurisdictions"
                :key="`jurisdictionFilter${index}`"
                @click.prevent="toggleFilterBy(jurisdiction)"
              >
                <input
                  type="checkbox"
                  :checked="filterBy[jurisdiction]"
                />
                <span>{{ jurisdiction }}</span>
              </label>
            </div>
          </div>
          <div class="filter-group">
            <div
              class="filter-group-title"
              @click="toggleFilterOpen(headerCells[2] ?? '')"
            >
              <div>
                {{ headerCells[2] }}
                <span v-if="countActiveFilters(species)">
                  ({{ countActiveFilters(species) }})</span>
              </div>
              <AppDropdownButton :open="openFilters[headerCells[2] ?? '']" />
            </div>
            <div v-if="openFilters[headerCells[2] ?? '']">
              <label
                v-for="(specie, index) in species"
                :key="`specieFilter${index}`"
                @click.prevent="toggleFilterBy(specie)"
              >
                <input
                  type="checkbox"
                  :checked="filterBy[specie]"
                />
                <span>{{ specie }}</span>
              </label>
            </div>
          </div>
          <div class="filter-group">
            <div
              class="filter-group-title"
              @click="toggleFilterOpen(headerCells[3] ?? '')"
            >
              <div>
                {{ headerCells[3] }}
                <span v-if="countActiveFilters(actTypes)">
                  ({{ countActiveFilters(actTypes) }})</span>
              </div>
              <AppDropdownButton :open="openFilters[headerCells[3] ?? '']" />
            </div>
            <div v-if="openFilters[headerCells[3] ?? '']">
              <label
                v-for="(actType, index) in actTypes"
                :key="`actTypeFilter${index}`"
                @click.prevent="toggleFilterBy(actType)"
              >
                <input
                  type="checkbox"
                  :checked="filterBy[actType]"
                />
                <span>{{ actType }}</span>
              </label>
            </div>
          </div>
          <div class="filter-group">
            <div
              class="filter-group-title"
              @click="toggleFilterOpen(headerCells[4] ?? '')"
            >
              <div>
                {{ headerCells[4] }}
                <span v-if="countActiveFilters(statuses)">
                  ({{ countActiveFilters(statuses) }})</span>
              </div>
              <AppDropdownButton :open="openFilters[headerCells[4] ?? '']" />
            </div>
            <div v-if="openFilters[headerCells[4] ?? '']">
              <label
                v-for="(status, index) in statuses"
                :key="`statusFilter${index}`"
                @click.prevent="toggleFilterBy(status)"
              >
                <input
                  type="checkbox"
                  :checked="filterBy[status]"
                />
                <span>{{ status }}</span>
              </label>
            </div>
          </div>
          <AppButton
            class="tablet-down"
            @click="showFilters = false"
          >
            Apply
          </AppButton>
          <AppModal modal-ref="downloadDatabase">
            <template #link>
              <AppButton>Download database (xlsx) <Icon name="icons:download" /></AppButton>
            </template>
            <template #modal>
              <template v-if="success">
                <h2>Thanks</h2>
                <p>Your download should start shortly.</p>
              </template>
              <template v-else>
                <h2>
                  Please provide the following details to receive the database
                  (xlsx)
                </h2>
                <form @submit.prevent="download">
                  <input
                    type="hidden"
                    name="form_endpoint_key"
                    value="download"
                  />
                  <input
                    type="hidden"
                    name="newsletter"
                    value="CALF"
                  />
                  <div class="text-fields">
                    <div>
                      <label>First name</label>
                      <input
                        type="text"
                        name="first_name"
                        required
                      />
                    </div>
                    <div>
                      <label>Last name</label>
                      <input
                        type="text"
                        name="last_name"
                        required
                      />
                    </div>
                    <div>
                      <label>Email address</label>
                      <input
                        type="email"
                        name="email"
                        required
                      />
                    </div>
                    <div>
                      <label>Organisation</label>
                      <input
                        type="text"
                        name="organisation"
                      />
                    </div>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="newsletter"
                        value="CALF"
                      />
                      <span>
                        I would like to subscribe to the CALF mailing list to
                        receive the latest news and analysis
                      </span>
                    </label>
                    <div class="submit">
                      <NuxtTurnstile
                        ref="turnstile"
                        v-model="turnstileToken"
                        :options="{
                          appearance: 'interaction-only',
                        }"
                      />
                      <AppButton
                        type="submit"
                        :disabled="!turnstileToken || pending"
                      >
                        Submit
                      </AppButton>
                    </div>
                  </div>
                </form>
              </template>
            </template>
          </AppModal>
          <div class="timestamp">
            Last Updated: {{ database?.lastUpdated }}
          </div>
        </div>
      </div>
      <div class="table">
        <table>
          <thead>
            <tr>
              <th
                v-for="(headerCell, index) in headerCells"
                :key="index"
              >
                <div>
                  {{ headerCell }}
                  <AppInfoButton
                    v-if="database"
                    :info-key="headerCell"
                    :database="database"
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="(row, index) in filteredRows.slice(0, loadTotal)"
              :key="`0${index}`"
            >
              <tr class="primary">
                <td>
                  <div>
                    <AppDropdownButton
                      :open="openRow === index"
                      @click="openRow = openRow === index ? null : index"
                    />
                    <div>
                      <NuxtLink :to="'/database/policy/' + row['Slug']">
                        <span>{{ row[headerCells[0] ?? ''] }}</span>
                      </NuxtLink>
                      <div>
                        <FlagIcon :flag="row[headers[1] ?? ''] ?? ''" />{{
                          row[headers[1] ?? '']
                        }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="topics">
                  <span
                    v-for="(topic, index2) in getTruthyTopics(row)"
                    :key="index2"
                  ><NuxtLink :to="getTopicUrl(topic ?? '')">{{
                    topic
                  }}</NuxtLink></span>
                </td>
                <td class="species">
                  <span
                    v-for="(specie, index2) in getTruthySpecies(row)"
                    :key="index2"
                    :class="specie?.toLowerCase().replaceAll(' ', '-')"
                  >{{ specie }}</span>
                </td>
                <td>{{ row[headerCells[3] ?? ''] }}</td>
                <td>{{ row[headerCells[4] ?? ''] }}</td>
              </tr>
              <tr
                v-if="openRow === index"
                :key="`1${index}`"
              >
                <td colspan="3">
                  <div class="tablet-down topics">
                    <div>{{ headerCells[1] }}</div>
                    <span
                      v-for="(topic, index2) in getTruthyTopics(row)"
                      :key="index2"
                    >{{ topic }}</span>
                  </div>
                  <div class="tablet-down species">
                    <div>{{ headerCells[2] }}</div>
                    <span
                      v-for="(specie, index2) in getTruthySpecies(row)"
                      :key="index2"
                      :class="specie?.toLowerCase().replaceAll(' ', '-')"
                    >{{ specie }}</span>
                  </div>
                  {{ row["Description"] }}
                </td>
                <td colspan="2">
                  <a
                    :href="row['Link to text']"
                    target="_blank"
                  >View original text<Icon name="icons:external-link" /></a>
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">
                You're viewing
                <strong>{{
                  loadTotal > filteredRows.length
                    ? filteredRows.length
                    : loadTotal
                }}/{{ filteredRows.length }}</strong>
                Entries
                <AppButton
                  v-if="loadTotal < (database?.data.length ?? 0)"
                  @click="loadTotal += loadChunk"
                >
                  Load more
                </AppButton>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </MarginContainer>
  </div>
</template>

<!-- eslint-disable-next-line vue/max-lines-per-block -->
<style lang="scss" scoped>
.data-table {
  section {
    display: flex;
    z-index: 4;

    @include tablet {
      flex-direction: column;
    }

    > button {
      padding: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 50%;
      margin-left: 50%;
      position: sticky;
      top: 24px;
      z-index: 1;

      span {
        color: $blue2;
      }

      .wrapper {
        height: 32px;
      }
    }

    .filters {
      background-color: $grey2;
      padding: 24px;
      min-width: 300px;

      @include tablet {
        display: none;
      }

      &.open {
        @include tablet {
          display: block;
          padding: 74px 16px;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 20;
          box-sizing: border-box;
          overflow-y: auto;
        }
      }

      > .wrapper {
        width: 32px;
        height: 32px;
        cursor: pointer;
        margin-bottom: 16px;
        position: absolute;
        top: 26px;
        right: 16px;

        @include desktop-only {
          display: none;
        }
      }

      > div {
        @include desktop-only {
          min-width: 300px;
          max-width: 300px;
          overflow-y: auto;
          position: sticky;
          top: 24px;
          z-index: 1;
        }
      }

      .search {
        min-width: 250px;

        .iconify {
          position: absolute;
          margin: 14px;
        }

        input {
          padding-left: 45px;
        }
      }

      .sort-by {
        margin-top: 16px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-weight: 700;
        font-size: rem-calc(18px);

        select {
          cursor: pointer;
          margin-left: 16px;
        }
      }

      .filter-group,
      .filter-header {
        border-bottom: 1px solid $blue3;

        .filter-group-title,
        label > span {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          margin: 16px 0;
        }

        .filter-group-title {
          font-weight: 700;

          span {
            color: $blue2;
          }
        }

        label {
          display: block;

          > span {
            flex-direction: row-reverse;

            &::before {
              color: $white1;
            }
          }
        }
      }

      .filter-header {
        display: flex;
        justify-content: space-between;
        padding: 16px 0;

        :nth-child(1) {
          font-weight: 700;
          font-size: rem-calc(18px);
        }

        :nth-child(2) {
          cursor: pointer;
        }

        .clear {
          font-weight: 700;
          color: $blue2;
        }
      }

      button {
        margin-top: 24px;
        width: 100%;
        padding: 24px;

        .wrapper {
          display: inline;
          height: 16px;
          vertical-align: middle;
          color: $white1;
        }
      }

      .timestamp {
        margin-top: 24px;
        font-size: rem-calc(20px);
      }
    }

    .table {
      padding: 16px;
      width: 100%;

      @include tablet {
        padding: 0;
        margin-top: 24px;
      }

      table {
        border-spacing: 8px;
        width: 100%;

        @include tablet {
          border-spacing: 0;
        }

        thead {
          tr {
            th {
              font-weight: 600;
              text-align: left;
              white-space: nowrap;
              cursor: pointer;
              position: sticky;
              top: 24px;

              @include mobile {
                top: 104px;
              }

              > div:first-child {
                &::before {
                  content: "";
                  position: absolute;
                  width: 100%;
                  height: calc(100% + 24px);
                  padding: 0 8px 24px;
                  top: -24px;
                  left: -8px;
                  background-image: linear-gradient(
                    to bottom,
                    $white1 80%,
                    rgba($white1, 0) 100%
                  );
                  z-index: -1;
                }
              }

              > div:last-child {
                padding: 12px 8px;
                background-color: $grey2;
                position: relative;
              }

              &:not(:first-child) {
                @include tablet {
                  display: none;
                }
              }

              > div {
                display: flex;
                align-items: center;
                gap: 4px;
              }
            }
          }
        }

        tbody {
          tr {
            &.primary:not(:first-child) {
              box-shadow: 0 -1px 0 0 $blue3;
            }

            &:not(.primary) {
              td {
                padding: 0 0 16px 34px;

                a {
                  font-size: rem-calc(16px);
                }
              }
            }

            &.primary {
              td {
                padding: 16px 0;
                vertical-align: top;

                &:first-child {
                  > div {
                    margin-top: 8px;
                    display: flex;

                    > div > div {
                      display: flex;
                      align-items: center;
                      gap: 8px;

                      > .iconify {
                        width: 32px;
                        height: 21px;
                      }
                    }

                    > .wrapper {
                      margin-right: 8px;
                      min-width: 24px;
                      height: 24px;
                    }
                  }
                }
              }
            }

            td {
              &:not(:first-child) {
                @include tablet {
                  display: none;
                }
              }

              a {
                color: $blue2;
                font-family: $font-secondary;
                font-size: rem-calc(18px);
                font-weight: 700;
                vertical-align: middle;

                .iconify {
                  height: 16px;
                  min-width: 16px;
                  margin-left: 8px;
                  margin-right: 0;
                  color: $blue2;
                }
              }

              .topics > div,
              .species > div {
                font-weight: 700;
                margin-bottom: 8px;
              }

              .species {
                margin-bottom: 16px;
              }
            }
          }
        }

        tfoot {
          tr {
            td {
              text-align: center;
              font-family: $font-secondary;
              font-size: rem-calc(18px);

              button {
                display: block;
                margin: 16px auto;
              }
            }
          }
        }
      }
    }

    form {
      > .text-fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > div {
          width: calc(50% - 12px);

          @include mobile {
            width: 100%;
          }

          label {
            margin-top: 24px;
            display: block;
            margin-bottom: 8px;
            font-family: $font-secondary;
            font-weight: 700;

            @include mobile {
              font-size: rem-calc(18px);
            }
          }

          input,
          select {
            background-color: $blue4;
            color: $blue1;
          }
        }
      }

      > div:not(.text-fields) {
        margin-top: 24px;

        label {
          font-size: rem-calc(12px);
          font-weight: normal;

          @include mobile {
            font-size: rem-calc(18px);
          }

          > span {
            display: flex;
            align-items: center;
            cursor: pointer;

            &::before {
              background-color: $blue4;
              font-size: rem-calc(16px);
              margin-right: 8px;
            }
          }
        }

        .submit {
          margin-top: 32px;

          @include mobile {
            display: flex;
            flex-direction: column;
            align-items: center;

            button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
