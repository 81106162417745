<script setup lang="ts">
defineProps<{
  policy: Record<string, string>;
}>();

const database = useDatabase();
</script>

<template>
  <div
    v-if="database"
    class="related-policies"
  >
    <MarginContainer>
      <div>
        <h1>Related Laws/Policies</h1>
        <NuxtLink to="/database">
          <AppButton>View database</AppButton>
        </NuxtLink>
      </div>
      <div class="cards">
        <NuxtLink
          v-for="(relatedPolicy, index) in getOrderedSimilar(policy).slice(0, 4)"
          :key="index"
          :to="`/database/policy/${relatedPolicy['Slug']}`"
        >
          <div>
            <h3>{{ relatedPolicy["Law / Policy"] }}</h3>
            <div>
              <FlagIcon
                v-if="relatedPolicy['Jurisdiction']"
                :flag="relatedPolicy['Jurisdiction']"
              />{{
                relatedPolicy["Jurisdiction"]
              }}
            </div>
          </div>
          <div>
            <div class="topics">
              <span
                v-for="(topic, topicIndex) in getTruthyTopics(relatedPolicy)"
                :key="topicIndex"
              >{{ topic }}</span>
            </div>
            <div class="species">
              <span
                v-for="(specie, specieIndex) in getTruthySpecies(relatedPolicy)"
                :key="specieIndex"
                :class="specie?.toLowerCase().replaceAll(' ', '-')"
              >{{ specie }}</span>
            </div>
          </div>
        </NuxtLink>
      </div>
    </MarginContainer>
  </div>
</template>

<style lang="scss" scoped>
.related-policies {
  section {
    padding-bottom: 120px;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      @include mobile {
        flex-direction: column;
      }

      .iconify {
        width: 32px;
        height: 21px;
        margin-right: 8px;
      }

      a {
        @include mobile {
          width: 100%;
        }
      }
    }

    .cards {
      margin-top: 26px;

      > a {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: $blue1;
        text-decoration: none;
        background-color: $blue4;
        width: calc(25% - 24px);
        border: 1px solid $blue2;
        border-radius: 2px;
        padding: 24px;
        height: 460px;
        box-sizing: border-box;

        @include tablet {
          width: calc(50% - 12px);
          height: 310px;
          margin-bottom: 24px;
        }

        @include mobile {
          width: 100%;
          height: initial;
          margin-bottom: 16px;
          padding: 16px;
        }

        > div:first-child {
          margin-bottom: 8px;
        }

        h3 {
          margin-top: 0;
          margin-bottom: 8px;
        }

        > div:first-of-type > div:first-of-type {
          display: flex;
        }
      }
    }
  }
}
</style>
