<script setup lang="ts">
defineProps<{
  policy: Record<string, string>;
}>();

const database = useDatabase();

const headers = useHeaders();

const headerCells = computed(() => [
  headers.value[6], // Description
  "Topic", // Hard-coded as text not in CSV
  "Species", // Hard-coded as text not in CSV
  headers.value[1], // Jurisdiction
  headers.value[2], // Sub-jurisdiction
  headers.value[3], // Type of act
  headers.value[4], // Status
  headers.value[5], // Legal value
  headers.value[9], // Date enacted
  headers.value[10], // Date updated
  headers.value[11], // Citation
  headers.value[7], // Strengths
  headers.value[8], // Weaknesses
]);
</script>

<template>
  <div
    v-if="database"
    class="policy-details"
  >
    <MarginContainer>
      <div>
        <MarginContainer class="narrow">
          <div>
            <h2>Summary Description</h2>
            <div>
              <dl class="full">
                <dd>{{ policy[headerCells[0] ?? ''] }}</dd>
              </dl>
              <dl>
                <dt>
                  {{ headerCells[1]
                  }}<AppInfoButton
                    :info-key="headerCells[1] ?? ''"
                    :database="database"
                  />
                </dt>
                <dd class="topics">
                  <span
                    v-for="(topic, index) in getTruthyTopics(policy)"
                    :key="index"
                  ><NuxtLink :to="topic && getTopicUrl(topic)">{{
                    topic
                  }}</NuxtLink></span>
                </dd>
              </dl>
              <dl>
                <dt>
                  {{ headerCells[2]
                  }}<AppInfoButton
                    :info-key="headerCells[2] ?? ''"
                    :database="database"
                  />
                </dt>
                <dd class="species">
                  <span
                    v-for="(specie, index) in getTruthySpecies(policy)"
                    :key="index"
                    :class="specie?.toLowerCase().replaceAll(' ', '-')"
                  >{{ specie }}</span>
                </dd>
              </dl>
              <dl>
                <dt>
                  {{ headerCells[3]
                  }}<AppInfoButton
                    :info-key="headerCells[3] ?? ''"
                    :database="database"
                  />
                </dt>
                <dd>{{ policy[headerCells[3] ?? ''] }}</dd>
              </dl>
              <dl>
                <dt>
                  {{ headerCells[4]
                  }}<AppInfoButton
                    :info-key="headerCells[4] ?? ''"
                    :database="database"
                  />
                </dt>
                <dd>{{ policy[headerCells[4] ?? ''] }}</dd>
              </dl>
              <dl>
                <dt>
                  {{ headerCells[5]
                  }}<AppInfoButton
                    :info-key="headerCells[5] ?? ''"
                    :database="database"
                  />
                </dt>
                <dd>{{ policy[headerCells[5] ?? ''] }}</dd>
              </dl>
              <dl>
                <dt>
                  {{ headerCells[6]
                  }}<AppInfoButton
                    :info-key="headerCells[6] ?? ''"
                    :database="database"
                  />
                </dt>
                <dd>{{ policy[headerCells[6] ?? ''] }}</dd>
              </dl>
              <dl>
                <dt>
                  {{ headerCells[7]
                  }}<AppInfoButton
                    :info-key="headerCells[7] ?? ''"
                    :database="database"
                  />
                </dt>
                <dd>{{ policy[headerCells[7] ?? ''] }}</dd>
              </dl>
              <dl>
                <dt>
                  {{ headerCells[8]
                  }}<AppInfoButton
                    :info-key="headerCells[8] ?? ''"
                    :database="database"
                  />
                </dt>
                <dd>{{ policy[headerCells[8] ?? ''] }}</dd>
              </dl>
              <dl>
                <dt>
                  {{ headerCells[9]
                  }}<AppInfoButton
                    :info-key="headerCells[9] ?? ''"
                    :database="database"
                  />
                </dt>
                <dd>{{ policy[headerCells[9] ?? ''] }}</dd>
              </dl>
              <dl>
                <dt>
                  {{ headerCells[10]
                  }}<AppInfoButton
                    :info-key="headerCells[10] ?? ''"
                    :database="database"
                  />
                </dt>
                <dd>{{ policy[headerCells[10] ?? ''] }}</dd>
              </dl>
            </div>
          </div>
          <div
            v-if="
              policy[headerCells[11] ?? ''] || policy[headerCells[12] ?? '']
            "
          >
            <h2>Strengths &amp; Weaknesses</h2>
            <div class="strengths-weaknesses">
              <dl v-if="policy[headerCells[11] ?? '']">
                <dt>{{ headerCells[11] }}</dt>
                <dd>
                  <ul>
                    <li
                      v-for="(bullet, bulletIndex) in parseBullets(
                        policy[headerCells[11] ?? ''] ?? '',
                      )"
                      :key="bulletIndex"
                    >
                      <template v-if="bullet.length === 1">
                        {{ bullet[0] }}
                      </template>
                      <template v-else>
                        {{ bullet[0] }}
                        <ol>
                          <li
                            v-for="(numbered, numberedIndex) in bullet.slice(1)"
                            :key="numberedIndex"
                          >
                            {{ numbered }}
                          </li>
                        </ol>
                      </template>
                    </li>
                  </ul>
                </dd>
              </dl>
              <dl v-if="policy[headerCells[12] ?? '']">
                <dt>{{ headerCells[12] }}</dt>
                <dd>
                  <ul>
                    <li
                      v-for="(bullet, bulletIndex) in parseBullets(
                        policy[headerCells[12] ?? ''] ?? '',
                      )"
                      :key="bulletIndex"
                    >
                      <template v-if="bullet.length === 1">
                        {{ bullet[0] }}
                      </template>
                      <template v-else>
                        {{ bullet[0] }}
                        <ol>
                          <li
                            v-for="(numbered, numberedIndex) in bullet.slice(1)"
                            :key="numberedIndex"
                          >
                            {{ numbered }}
                          </li>
                        </ol>
                      </template>
                    </li>
                  </ul>
                </dd>
              </dl>
            </div>
          </div>
        </MarginContainer>
      </div>
    </MarginContainer>
  </div>
</template>

<style lang="scss" scoped>
.policy-details {
  > section {
    > div {
      border-bottom: 1px solid $blue3;

      > section {
        padding: 0;

        > div:nth-child(1) {
          border-bottom: 1px solid $blue3;
          padding-bottom: 23px;
        }

        > div:nth-child(2) {
          padding-bottom: 60px;
        }

        > div {
          > div {
            dl {
              margin: 16px 0;
              display: flex;

              &.full {
                display: block;

                dt,
                dd {
                  margin: 24px 0;
                  font-size: rem-calc(20px);

                  @include mobile {
                    margin: 8px 0;
                  }
                }
              }

              @include mobile {
                display: block;
              }

              dt {
                font-family: $font-secondary;
                font-weight: 700;
                font-size: rem-calc(18px);
                min-width: 33%;
                display: flex;
                align-items: center;
                gap: 4px;
              }

              dd {
                font-family: $font-body;
              }

              dt,
              dd {
                margin: 0;

                @include mobile {
                  margin: 8px 0;
                }
              }
            }

            &.strengths-weaknesses {
              display: flex;
              flex-wrap: initial;

              @include mobile {
                flex-direction: column;
              }

              dl {
                display: block;
                width: 50%;

                @include mobile {
                  width: auto;
                }

                dt {
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
