<script setup lang="ts">
import type { Copy } from "~/types/prismic.js";

defineProps<{
  copy: Copy[];
  successCopy: Copy[];
}>();

const pending = ref(false);

const success = ref(false);

const handle = async (event: Event) => {
  pending.value = true;

  const formData = new FormData(event.target as HTMLFormElement);

  try {
    const data = await $fetch("/api/pardot/submit", {
      method: "POST",
      body: Object.fromEntries(formData.entries()),
    });

    if (data === "ok") {
      success.value = true;
    } else {
      throw new Error(data);
    }
  } catch (error) {
    alert("Something went wrong, please try again later");

    pending.value = false;

    // eslint-disable-next-line no-console
    console.error(error);
  }

  turnstile.value?.reset();

  pending.value = false;
};

const turnstile = ref();

const turnstileToken = ref("");
</script>

<template>
  <div class="newsletter-subscribe">
    <MarginContainer>
      <PrismicText :blocks="copy" />
      <PrismicText
        v-if="success"
        :blocks="successCopy"
      />
      <form
        v-else
        @submit.prevent="handle"
      >
        <input
          type="hidden"
          name="form_endpoint_key"
          value="newsletter"
        />
        <input
          type="hidden"
          name="newsletter"
          value="CALF"
        />
        <div class="text-fields">
          <div>
            <label>First name</label>
            <input
              type="text"
              name="first_name"
              required
            />
          </div>
          <div>
            <label>Last name</label>
            <input
              type="text"
              name="last_name"
              required
            />
          </div>
          <div>
            <label>Email address</label>
            <input
              type="email"
              name="email"
              required
            />
          </div>
          <div>
            <label>Organisation</label>
            <input
              type="text"
              name="organisation"
            />
          </div>
        </div>
        <div>
          <label>
            <span>
              By submitting this form, I confirm I would like to subscribe to
              the CALF mailing list to receive the latest news and analysis.
            </span>
          </label>
          <div class="submit">
            <NuxtTurnstile
              ref="turnstile"
              v-model="turnstileToken"
              :options="{
                appearance: 'interaction-only',
              }"
            />
            <AppButton
              type="submit"
              :disabled="!turnstileToken || pending"
            >
              Submit
            </AppButton>
          </div>
        </div>
      </form>
    </MarginContainer>
  </div>
</template>

<style lang="scss" scoped>
.newsletter-subscribe {
  background-color: $blue1;
  color: $white1;

  section {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 40px;
    background-color: transparent;

    @include tablet {
      flex-direction: column;
    }

    > div:nth-child(1) {
      min-width: 40%;
      margin-right: 24px;

      @include tablet {
        margin-right: 0;
      }

      :deep(.prismic-link) {
        text-decoration: none;
        font-weight: 700;
      }
    }

    form {
      > .text-fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > div {
          width: calc(50% - 12px);

          @include mobile {
            width: 100%;
          }

          label {
            margin-top: 24px;
            display: block;
            margin-bottom: 8px;
            font-family: $font-secondary;
            font-weight: 700;
          }

          input,
          select {
            background-color: rgba($white1, 0.1);
            color: $white1;
          }
        }
      }

      > div:not(.text-fields) {
        margin-top: 24px;

        label {
          font-size: rem-calc(12px);
          font-weight: normal;

          > span {
            display: flex;
            align-items: center;
            cursor: pointer;

            &::before {
              background-color: rgba($white1, 0.1);
              font-size: rem-calc(16px);
              margin-right: 8px;
            }
          }
        }

        .submit {
          margin-top: 32px;

          @include mobile {
            display: flex;
            flex-direction: column;
            align-items: center;

            button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
