<script setup lang="ts">
import { PrismicLink } from "#components";
import type { Card } from "~/types/prismic.js";

defineProps<{
  cards: Card[];
}>();

const cardsContainer = ref<null | HTMLElement>(null);

const VISIBLE_CARDS = 1;

const scrollPosition = ref(0);

const canScrollLeft = computed(
  () => import.meta.client && !!cardsContainer.value?.children[scrollPosition.value - 1],
);

const canScrollRight = computed(
  () =>
    import.meta.client && !!cardsContainer.value?.children[scrollPosition.value + VISIBLE_CARDS],
);

const scrollLeft = () => {
  if (cardsContainer.value && canScrollLeft.value) {
    cardsContainer.value.children[--scrollPosition.value]?.classList.remove("scrolled");
  }
};

const scrollRight = () => {
  if (cardsContainer.value && canScrollRight.value) {
    cardsContainer.value.children[scrollPosition.value++]?.classList.add("scrolled");
  }
};
</script>

<template>
  <div class="carousel-compact">
    <MarginContainer>
      <div>
        <div>
          <div
            v-if="cards && cards.length > VISIBLE_CARDS"
            class="buttons"
          >
            <AppButton
              :disabled="!canScrollLeft"
              @click="scrollLeft"
            >
              <Icon
                name="icons:arrow-left"
                :size="24"
              />
            </AppButton>
            <AppButton
              :disabled="!canScrollRight"
              @click="scrollRight"
            >
              <Icon
                name="icons:arrow-right"
                :size="24"
              />
            </AppButton>
          </div>
          <div
            v-if="cards"
            ref="cardsContainer"
            v-touch:swipe.left="scrollRight"
            v-touch:swipe.right="scrollLeft"
            class="cards"
          >
            <component
              :is="card.link ? PrismicLink : 'div'"
              v-for="(card, index) in cards"
              :key="index"
              :link="card.link"
            >
              <NuxtImg
                v-if="card.image"
                :src="card.image.url"
              />
            </component>
          </div>
        </div>
      </div>
    </MarginContainer>
  </div>
</template>

<style lang="scss" scoped>
.carousel-compact {
  font-size: rem-calc(20px);
  overflow-x: hidden;

  section {
    > div {
      border-top: 1px solid $blue3;
      padding-top: 16px;

      > div:nth-child(1) {
        overflow-x: hidden;
        position: relative;
        display: flex;
        justify-content: center;

        > div:nth-child(1) {
          position: absolute;
          height: 100%;
          width: calc(100% - 48px);
          z-index: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;

          :deep(button) {
            padding: 18px;

            @include mobile {
              width: auto;
            }
          }
        }

        .cards {
          display: flex;
          justify-content: flex-start;
          gap: 24px;

          > div,
          > .prismic-link {
            box-sizing: border-box;
            min-width: 100%;
            width: 100%;
            background-color: $blue4;
            border-radius: 2px;
            border: 1px solid $blue2;
            overflow: hidden;
            color: $blue1;
            text-decoration: none;
            transition: margin-left 300ms;

            &.scrolled {
              margin-left: calc(-100% - 24px);
            }

            img {
              display: block;
              width: 100%;
              aspect-ratio: 16/9;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
</style>
