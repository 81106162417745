<script setup lang="ts">
import type { Copy } from "~/types/prismic.js";

defineProps<{
  copy: Copy[];
  highlight: boolean;
}>();

const copyEl = ref<null | HTMLElement>(null);

const setContentCopyMargin = () => {
  if (copyEl.value) {
    useContentCopyMargin().value
      = copyEl.value.getBoundingClientRect().x > 289
        ? 289
        : copyEl.value.getBoundingClientRect().x;
  }
};

onMounted(() => {
  window.addEventListener("resize", setContentCopyMargin);

  setContentCopyMargin();
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", setContentCopyMargin);
});
</script>

<template>
  <div class="content-copy">
    <MarginContainer class="narrow">
      <div
        ref="copyEl"
        :class="{ highlight }"
      >
        <PrismicText :blocks="copy" />
      </div>
    </MarginContainer>
  </div>
</template>

<style lang="scss" scoped>
.content-copy {
  section {
    > div {
      &.highlight {
        background-color: $blue4;
        padding: 40px 24px;
        border-top: 5px solid $blue2;
      }

      :deep(h1, h2, h3, h4, h5, h6) {
        &:first-child {
          margin-top: 0;
        }
      }

      :deep(a) {
        background-color: $blue4;
        padding: 2px 4px;
        box-decoration-break: clone;
        font-weight: 700;
      }
    }
  }
}
</style>

<style lang="scss">
.article .content-copy {
  .image img {
    width: 100%;
  }
}
</style>
