<script setup lang="ts">
import type { Database } from "~/types/database.js";
import type {
  Breadcrumb,
  Button,
  Card,
  ContentNavLink,
  Copy,
  Filter,
  Link,
  Video,
} from "~/types/prismic.js";

const database = useDatabase();

const content = useContent();

const policy = usePolicy();

const route = useRoute();

useHead({
  title: content.value?.meta_title,
});

useSeoMeta({
  description: content.value?.meta_description,
});

const isArticle = computed(
  () => !!content.value?.body.find((b) => b.__typename === "PageBodyArticle_header"),
);
</script>

<template>
  <Transition
    name="fade"
    mode="out-in"
    appear
  >
    <article v-if="database && content">
      <div
        :class="{ article: isArticle }"
      >
        <template v-for="(section, index) in content.body">
          <PrismicText
            v-if="section.__typename === 'PageBodyText'"
            :key="`section${index}`"
            :blocks="(section as unknown as Copy[])"
          />
          <SliceFeature
            v-if="section.__typename === 'PageBodyFeature' && section.primary"
            :key="`section${index}`"
            :theme="(section.primary.feature_theme as string)"
            :copy="(section.primary.feature_copy as Copy[])"
            :button-link="(section.primary.feature_link as Link)"
            :button-text="(section.primary.feature_link_copy as string)"
            :background-url="((section.primary.feature_background as Link || {}).url as string)"
            :image-url="((section.primary.feature_image as Link || {}).url as string)"
            :alignment="(section.primary.feature_alignment as string)"
          />
          <SliceBreadcrumbs
            v-if="section.__typename === 'PageBodyBreadcrumbs'"
            :key="`section${index}`"
            :links="(section.fields as Breadcrumb[] || [])"
          />
          <SliceTable4Column
            v-if="
              section.__typename === 'PageBodyTable_4_column' && section.primary
            "
            :key="`section${index}`"
            :header1="String(section.primary.header1)"
            :header2="String(section.primary.header2)"
            :header3="String(section.primary.header3)"
            :header4="String(section.primary.header4)"
            :rows="(section.fields as Database['data'])"
          />
          <SlicePageHeader
            v-if="section.__typename === 'PageBodyPage_header' && section.primary"
            :key="`section${index}`"
            :is-legislative="route.path.includes('legislative')"
            :title="(section.primary.title as Copy[])"
            :subtitle="(section.primary.subtitle as Copy[])"
            :read-more="(section.primary.read_more as Copy[])"
            :background-image="((section.primary.background_image as Link || {}).url as string)"
            :background-video="((section.primary.background_video as Link || {}).url as string)"
            :absolute-top="(section.primary.absolute_top as boolean)"
            :button-link="(section.primary.button_link as Link)"
            :button-text="(section.primary.button_text as string)"
          />
          <SliceArticleHeader
            v-if="
              section.__typename === 'PageBodyArticle_header' && section.primary
            "
            :key="`section${index}`"
            :title="(section.primary.title as Copy[])"
            :button-link="(section.primary.button_link as Link)"
            :jurisdiction="(section.primary.jurisdiction as string)"
          />
          <SliceDataTable
            v-if="section.__typename === 'PageBodyData_table' && section.fields"
            :key="`section${index}`"
            :enable-filters="true"
            :init-filters="(section.fields as Filter[]).map((f) => f.filter_column)"
          />
          <SlicePolicyHeader
            v-if="section.__typename === 'PageBodyPolicy_header' && policy"
            :key="`section${index}`"
            :policy="policy"
          />
          <SlicePolicyDetails
            v-if="section.__typename === 'PageBodyPolicy_details' && policy"
            :key="`section${index}`"
            :policy="policy"
          />
          <SliceRelatedPolicies
            v-if="section.__typename === 'PageBodyRelated_policies' && policy"
            :key="`section${index}`"
            :policy="policy"
          />
          <SliceNewsletterSubscribe
            v-if="
              section.__typename === 'PageBodyNewsletter_subscribe'
                && section.primary
            "
            :key="`section${index}`"
            :copy="(section.primary.copy as Copy[])"
            :success-copy="(section.primary.success_copy as Copy[])"
          />
          <SliceContactForm
            v-if="section.__typename === 'PageBodyContact_form' && section.primary"
            :key="`section${index}`"
            :copy="(section.primary.copy as Copy[])"
            :success-copy="(section.primary.success_copy as Copy[])"
          />
          <SliceContentPush
            v-if="section.__typename === 'PageBodyContent_push' && section.primary"
            :key="`section${index}`"
            :copy="(section.primary.copy as Copy[])"
            :button-link="(section.primary.button_link as Link)"
            :button-text="(section.primary.button_text as string)"
            :feature-image="((section.primary.feature_image as Link || {}).url as string)"
            :feature-image-width="(section.primary.feature_image_width as number)"
            :feature-image-link="(section.primary.feature_image_link as Link)"
            :feature-video="(section.primary.feature_video as Video)"
            :cards="
              section.fields
                ? (section.fields as Card[]).filter((f) => f.image || f.title)
                : []
            "
            :cards-per-row="(section.primary.cards_per_row as number)"
          />
          <SliceImagesCopy
            v-if="
              section.__typename === 'PageBodyImages___copy' && section.primary
            "
            :key="`section${index}`"
            :copy="(section.primary.copy as Copy[])"
            :cards="section.fields ? section.fields as Card[] : []"
          />
          <SliceCarouselPush
            v-if="
              section.__typename === 'PageBodyCarousel_push' && section.primary
            "
            :key="`section${index}`"
            :copy="(section.primary.copy as Copy[])"
            :side-copy="(section.primary.side_copy as Copy[])"
            :button-link="(section.primary.button_link as Link)"
            :button-text="(section.primary.button_text as string)"
            :cards="
              section.fields
                ? (section.fields as Card[]).filter((f) => f.image || f.title)
                : []
            "
          />
          <SliceButtonPush
            v-if="section.__typename === 'PageBodyButton_push' && section.primary"
            :key="`section${index}`"
            :copy="(section.primary.copy as Copy[])"
            :feature-image="((section.primary.feature_image as Link || {}).url as string)"
            :feature-image-width="(section.primary.feature_image_width as number)"
            :feature-video="(section.primary.feature_video as Video)"
            :buttons="
              section.fields
                ? (section.fields as Button[]).filter((f) => f.button_link && f.button_text)
                : []
            "
            :is-legislative="route.path.includes('legislative')"
          />
          <SliceContentCopy
            v-if="section.__typename === 'PageBodyContent_copy' && section.primary"
            :key="`section${index}`"
            :copy="(section.primary.copy as Copy[])"
            :highlight="!!section.primary.highlight"
          />
          <SliceContentNavigation
            v-if="section.__typename === 'PageBodyContent_navigation'"
            :key="`section${index}`"
            :links="(section.fields as ContentNavLink[])"
            :is-article="isArticle"
          />
          <SliceCollaboratorsPush
            v-if="
              section.__typename === 'PageBodyCollaborators_push'
                && section.primary
            "
            :key="`section${index}`"
            :copy="(section.primary.copy as Copy[])"
            :feature-logo="((section.primary.feature_logo as Link || {}).url as string)"
            :feature-copy="(section.primary.feature_copy as Copy[])"
            :cards="section.fields ? section.fields as Card[] : []"
          />
          <SliceCarouselCompact
            v-if="section.__typename === 'PageBodyCarousel_compact'"
            :key="`section${index}`"
            :cards="
              section.fields
                ? (section.fields as Card[]).filter((f) => f.image)
                : []
            "
          />
        </template>
      </div>
    </article>
    <AppLoading
      v-else
      loading-text="Loading page"
    />
  </Transition>
</template>

<style lang="scss">
.article {
  .content-copy {
    margin-right: -257px;
  }

  .content-navigation section .side-nav {
    max-width: 337px;
  }
}
</style>
